.kyc-page .title-page h2:after{
  left: 100px!important;
  width: calc(100% - 100px)!important;
}


.kyc-content label {
  color: #fff;
  font-family: 'Roboto-Bold'!important;
  font-style: normal;
}

.kyc-content .form-control {
  background: transparent;
  border-radius: 3px;
  border: 1px solid #ffffff;
  padding: 10px 20px;
  font-size: 14px;
  color: #999;
}

.kyc-content .form-control::placeholder {
  color: #999;
}
.kyc-content .dropdown-toggle {
  background: transparent;
  border: 1px solid #fff;
  width: 100%;
  text-align: left;
  padding: 15px 20px;
  text-transform: inherit;
  border-image-slice: 1!important;
  border-width: 1px!important;
  border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253)!important;
}
.kyc-content .dropdown-toggle:focus {
  background: transparent!important;
  border: 1px solid #fff!important;
  outline: none!important;
  box-shadow: none;
}
.kyc-content .dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 20px;
}
.kyc-content .show>.btn-primary.dropdown-toggle{
  background-color: transparent;
  border-color: #fff;
}
.kyc-content .dropdown-menu {
  background: #050c1b;
  width: 100%;
  border: 1px solid #949494;
  margin-top: 0px;
  position: absolute!important;
  transform: inherit!important;
  margin-bottom: 20px;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 49px!important;
}
.kyc-content .exposed .dropdown-menu,
.kyc-content .addressproof .dropdown-menu {
  background: #050c1b;
  width: 100%;
  border: 1px solid #949494;
  margin-top: 0px;
  position: absolute!important;
  transform: inherit!important;
  margin-bottom: 20px;
  height: 70px;
  top: 49px!important;
  overflow-y: hidden;
  overflow-x: hidden;
}
.kyc-content .idproof .dropdown-menu{
  background: #050c1b;
  width: 100%;
  border: 1px solid #949494;
  margin-top: 0px;
  position: absolute!important;
  transform: inherit!important;
  margin-bottom: 20px;
  height: 96px;
  top: 49px!important;
  overflow-y: hidden;
  overflow-x: hidden;
}
.kyc-content .dropdown-item:hover {
  background: #091e3ed1;
  color: #fff;
}
.kyc-content .dropdown-item {
  font-size: 12px;
  color: #fff;
  padding: 5px 15px;
}
.kyc-content .dropdown-toggle:hover {
  background-color: transparent;
  border-color: #3bbdf5;
}
.kyc-content .dropdown-toggle::after {
  background: url(../../assets/images/header/select-arrow.png);
  content: "";
  border: none;
  display: block;
  width: 18px;
  height: 9px;
  background-size: cover;
}
.kyc-content .show .dropdown-toggle::after {
  transform: rotate(180deg);
}
.kyc-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  display: block;
  margin-top: 10px;
}

.kyc-content input[type="file"]:disabled {
  display: none;
}


.kyc-message p {
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.kyc-message {
  padding: 30px 0px;
}

.kyc-content form {
  clear: both;
  padding-top: 10px;
}
.personal-detail{
  padding: 0px 15px;
}
.kyc-content .personal-detail h5{
  color: #fff;
}
.kyc-content .personal-detail p{
  color: #fff;
  border: 2px solid #143852;
  padding: 10px 15px;
  margin: 20px 0px;
}
.kyc-content hr{
 border-color: #fff;
}
input[type="file"] {
  width: 100px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
}

input[type="file"]::before {
  content: "Upload";
  background: #3bbdf5;
  padding: 20px 30px;
  color: #fff;
  border-radius: 5px;
  overflow: hidden;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    -webkit-appearance: none;
}

.kyc-content input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  font-family: 'FontAwesome';
  content: '\f073';
  width: 15px;
  height: 20px;
  position: absolute;
  top: 48px;
  right: 36px;
  color: #fff;
}
.kyc-content .address-img img{
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-top: -40px;
  margin-left: auto;
}
.kyc-content .idproof-img img{
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-top: -40px;
  margin-left: auto;
}
.kyc-content .idproof-img .form-group {
  display: flex;
  flex-flow: column;
}
.kyc-content .address-img .form-group {
  display: flex;
  flex-flow: column;
}
.kyc-content .css-yk16xz-control {
  background: transparent;
    border-radius: 3px;
    border: 1px solid #ffffff;
    padding: 5px 10px;
    font-size: 14px;
    color: #999;
    border-image-slice: 1!important;
    border-width: 1px!important;
    border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253)!important;
}
.kyc-content .css-2613cly-menu {
    background: #050c1b!important;
    width: 100%;
    border: 1px solid #949494!important;
    margin-top: 0px !important;
    position: absolute!important;
    transform: inherit!important;
    margin-bottom: 20px !important;
    height: 70px;
    top: 49px!important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}
.kyc-content .form-group .css-2b097c-container:focus {
  background-color: transparent !important;
  outline: none;
  box-shadow: none;
}
.css-1pahdxg-control {
  background-color: transparent !important;
  outline: none;
  box-shadow: none;
}
.css-g1d714-ValueContainer{
  background-color: transparent !important;
}
.css-g1d714-ValueContainer:focus{
  background-color: transparent !important;
  box-shadow: none!important;
  outline: none!important;
  border-color: #3bbdf5!important;
  color: #fff !important;
}
.kyc-content .css-1pahdxg-control:hover{
    background: transparent;
    border-radius: 3px;
    border: none !important;
    padding: 5px 10px;
}
.kyc-content .css-1pahdxg-control:focus{
box-shadow: none !important;
outline: none !important;
}
/* .css-yk16xz-control:hover {
  
  color: #999;
  border-image-slice: 1!important;
  border-width: 1px!important;
  border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253)!important;
} */

.kyc-page.pl .title-page h2:after {
  left: 425px!important;
  width: calc(100% - 425px)!important;
}

section.tournament-page.kyc-bg.kyc-page {
    background-attachment: fixed;
    background-position: center;
}
.country-list .css-1uccc91-singleValue {
    color: white !important;
}
.country-list .css-1wa3eu0-placeholder{
   color: white !important;
}
.kyc-content input::placeholder {
    color: white !important;
}
.kyc-content .form-control {
    background: transparent;
    border-radius: 3px;
    border: 1px solid #ffffff;
    padding: 10px 20px;
    font-size: 14px;
    color: white;
}