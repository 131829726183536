.editprofile {
    padding: 75px 0px;
    /* background: #111 url("../../assets/images/home/legal-bg.jpg"); */
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.font-weight-bold {
    font-weight: 700!important;
}
.breadcrumb {
    padding: 3px 0;
    margin-bottom: 0;
    background-color: transparent;
    border-radius: 0;
}

.text-muted {
    color: #6c757d!important;
}
.breadcrumb {
    padding: 3px 0;
    margin-bottom: 0;
    background-color: transparent;
    border-radius: 0;
}
.edit-p {
    padding: 80px 40px;
    max-width: 800px;
    margin: 0px auto;

}
.e-btn.btn.btn-primary:hover {
    background: #1c1c1c;
    border: #1c1c1c;
    box-shadow: none;
}
.e-btn.btn.btn-primary {
    padding: 12px 40px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    border-radius: 40px;
    background: #8f1c1c;
    border: #8f1c1c;
}
.edit-p h1 {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    max-width: 430px;
    margin: 0px auto;
    margin-bottom: 40px;
}
.edit-p h1 span {
 
    color: #8f1c1c;
}


.profile-img-box{
    max-width: 150px;
    margin: 0px auto;
    display: block;
    
}
.profile-img{
    border-radius: 50%;
}


.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .btn-upload {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .edit-p h2 {
    font-size: 35px;
    text-align: center;
    font-family: 'Molot';
    color: #fff;
    padding-bottom:30px
}

.edit-p p {
    font-size: 13px;
    color: #c1c1c1;
    text-align:center;
}

.editprofile .form-control {
    background: transparent;
border-radius: 3px;
border: 1px solid #ffffff;
padding: 10px 20px;
font-size: 14px;
}

.editprofile .form-label {
color: #fff;
font-family: 'Roboto-Bold'!important;
}

/* .editprofile .btn-primary {
    margin-top: 30px;
} */


/*************** dropdown css ******************/

.editprofile .dropdown-toggle {
    background: transparent;
    border: 1px solid #fff;
    width: 100%;
    text-align: left;
    padding: 15px 20px;
    text-transform: inherit;
    border-image-slice: 1!important;
    border-width: 1px!important;
    border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253)!important;
  }
  
  
  .editprofile .dropdown-toggle:focus {
    background: transparent!important;
    border: 1px solid #fff!important;
    outline: none!important;
    box-shadow: none;
  }
  
  
  .editprofile .dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 20px;
  }
  
  .editprofile .show>.btn-primary.dropdown-toggle{
  background-color: transparent;
  border-color: #fff;
  }
  
  
  .editprofile .dropdown-menu {
  background: #05020f3d;
  width: 100%;
  border: 1px solid #949494;
  margin-top: 0px;
  position: relative!important;
  transform: inherit!important;
  margin-bottom: 20px;
  }
  
  .editprofile .dropdown-item:hover {
  background: #091e3ed1;
  color: #fff;
  }
  
  .editprofile .dropdown-item {
  font-size: 12px;
  color: #fff;
  padding: 5px 15px;
  }
  
  
  .editprofile .dropdown-toggle:hover {
  background-color: transparent;
  border-color: #3bbdf5;
  }
  
  .editprofile .dropdown-toggle::after {
  background: url(../../assets/images/header/select-arrow.png);
  content: "";
  border: none;
  display: block;
  width: 18px;
  height: 9px;
  background-size: cover;
  }
  
  
  .editprofile .show .dropdown-toggle::after {
  transform: rotate(180deg);
  }
  
  