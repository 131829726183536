.admin-view .header, .admin-view .footer {
    display: none;
}

.admin-wrapper {
    overflow-x: hidden;
}

.admin-view .main_content {
    padding: 0;
}

.header-view {
    text-align: center;
}

.header-top-box h1 {
    font-size: 30px;
    padding: 5px 0px;
}


.admin-content-view {
    display: flex;
}

.admin-menu {
    min-height: 100vh;
    border-right: 1px solid #0d111e;
    width: 250px;
    background: #0d111e;
    transition: all 0.5s;
}

.admin-content {
    padding: 30px 20px;
}

.admin-container {
    width: calc(100% - 250px);
    background: #f9fbfd;
    transition: all 0.5s;
}


.admin-menu.menu-hide {
    width: 0;
    
}

.admin-container.wrapper-expand {
    width: 100%;
}



.menu-list ul {
    margin: 0;
    padding: 0;
}

.menu-list ul li a {
    padding: 15px 25px;
    display: block;
    color: #7f7f7f;
    text-decoration: none;
    transition: all 0.5s;
}

.admin-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
}

.menu-logo {
    width: 250px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
}


.admin-info img {
    width: 35px;
    height: 35px;
    object-fit: contain;
    border-radius: 50px;
    margin-right: 10px;
}

.admin-info {
    display: flex;
    align-items: center;
}

.menu-logo img {
    max-width: 220px;
    width: 100%;
    padding: 10px;
}


.menu-toggle img {
    width: 35px;
    opacity: .9;
    transition: all 0.5s;
}

.menu-toggle img:hover {
    opacity: 1;
}

.menu-list ul li a:hover {
    color: #f1454f;
}

.admin-info h3 {
    margin: 0;
    font-size: 14px;
    color: #212529;
    font-weight: 600;
}

.menu-list ul li a i {
    margin-right: 10px;
    width: 20px;
}

.dashboard .card {
    margin-bottom: 15px;
    margin-top: 15px;
    border-color: #edf2f9;
    box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
    background: #fff;
    border: none;
}

.menu-toggle a {
    color: #555;
    font-size: 20px;
}


.admin-title {
    margin: 0;
    padding-bottom: 20px;
    font-size: 30px;
    font-weight: 600;
}

.font-weight-600 {
    font-weight: 600;
}

.dashboard .fa {
    font-size: 30px;
    opacity: 0.2;
}

.dashboard .card-body{
    cursor: pointer;
    padding: 20px;
    border: none;
}

.dashboard .card-body .fa {
    transition: all 0.5s;
}

.dashboard .card-body:hover .fa {
    opacity: 1;
    color: #f1454f!important;
}


.user-list .table-responsive>.table-bordered .btn {
    background: #373373;
    border: none;
    padding: 5px 20px;
    font-size: 14px;
}

.user-list .table td, .table th {
    padding: 15px 20px;
}

.user-list  .table-responsive>.table-bordered {
    border: 0;
    background: #fff;
}


.user-list .table td {
    font-size: 14px;
}

.action-buttons .btn {
    margin: 0px 5px;
}


.block-btn {
    background: #f1454f!important;
}

.approve-btn {
    background: #37a864!important;
}

.edit-btn {
    background: #6d6d6d!important;
}

.admin-search {
    text-align: right;
    padding-bottom: 25px;
}


.admin-search .form-inline {
    justify-content: flex-end;
}

.admin-search .btn {
    background: #373373;
    border: none;
}

.admin-login {
    background: #f9f9f9;
    padding: 100px 0px;
    min-height: 100vh;
}

.admin-login-box {
    padding: 50px 40px;
    max-width: 500px;
    margin: 0px auto;
    background: #fff;
}

.admin-login-box .form-group .form-control {
    height: auto;
    padding: 10px 20px;
    background: transparent;
    border-radius: 4px;
    font-size: 14px;
    border-image-slice: inherit!important;
    border-width: inherit!important;
    border-image-source: inherit!important;
    border: 1px solid #dadada!important;
}


.admin-login-box .form-group .form-control:focus{
    box-shadow: none;
    background: transparent;
}
.admin-login-box input:-internal-autofill-selected{
    background: transparent!important;
}

.admin-login-box h2 {
    font-size: 35px;
    text-align: center;
    font-weight: 600;
    color: #333;
    padding-bottom: 20px;
}



/********* 20  Oct 2020 *********/



.admin-content select {
    padding: 5px 10px;
    height: 40px!important;
    width: 300px!important;
    border: 1px solid #c1c1c1;
}


.admin-content select:focus {
    outline: none;
}

.admin-content .container .container form .row {
    border-bottom: 1px solid #ddd;
    padding: 10px 15px;
}


.admin-content .container .container form h5 {
    padding-top: 20px;
    font-weight: 600;
    font-size: 22px;
}


.admin-content .container .container form .row > div {
    width: 35vw!important;
}

.admin-content .container .container form .row input:focus{
    outline: none;
}

.admin-content .container .container form .row input {
    width: 100%;
    border: none;
    padding: 0 10px;
    border-radius: 3px;
    font-size: 14px;
    height: 100%;
    margin: 0!important;
}
.admin-content .container .container form .row textarea {
    width: 100%;
    border: none;
    padding: 0 10px;
    border-radius: 3px;
    font-size: 14px;
    height: 100%;
    margin: 0!important;
}
.admin-content .container .container form .row div:last-child {
    border: 1px solid #c1c1c1;
    background: #fff;
    border-radius: 3px;
}

.admin-content .container .container form input[type="submit"] {
    background: #ff0031;
    border: 1px solid#ff0031;
    color: #fff;
    padding: 5px 20px;
    margin-top: 20px;
    border-radius: 5px;
}



.admin-content .container .container form input[type="reset"] {
    background:#8a8a8a;
    border: 1px solid#8a8a8a;
    color: #fff;
    padding: 5px 20px;
    margin-top: 20px;
    border-radius: 5px;
}

.admin-content .container .container form input[type="submit"]:hover, .admin-content .container .container form input[type="reset"]:focus {
    outline: none;
}


.admin-content .table td button {

    background: #ff0031;
    border: 1px solid#ff0031;
    color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
}


.admin-content .table td button {
    outline: none;
}


.admin-content .table th {
    color: #333;
}

.delete-popup .modal-body {
    text-align: center;
}

.delete-popup .modal-body h6 {
    max-width: 400px;
    margin: 0px auto;
    padding-bottom: 20px;
}



.delete-popup .modal-body button {
    background: #ff0031;
    border: 1px solid#ff0031;
    color: #fff;
    padding: 5px 20px;
    margin-top: 20px;
    border-radius: 5px;
}



.delete-popup .modal-body button:last-child {
    background:#8a8a8a;
    border: 1px solid#8a8a8a;

}

.dod-popup.delete-popup .modal-content {
    min-height: 100vh;
}


.delete-popup h3 {
    padding-top: 50px;
    padding-bottom: 10px;
    font-family: "Molot";
    letter-spacing: 0.5px;
    font-size: 30px;
}



/******************************* Media SCreen ******************************/


@media screen and (max-width:767px){
    .admin-container {
        width: 100%;
    }

    .admin-menu {
        width: 0;
    }

    .admin-search .form-inline {
        justify-content: center;
    }

    .admin-search .btn {
        margin-top: 10px;
    }

    .user-list .table-responsive>.table-bordered .btn {
        min-width: 100px;
        margin-bottom: 5px;
    }

    .admin-menu.menu-hide {
        width: 250px;
    }

}
/**** delete-table css **********/
.delete-table tr td{
    padding: 15px 20px!important;
    text-align: center;
}
.delete-table  tr th {
    padding: 15px 20px;
    text-align: center;
}





