
.notificationpage{
  background: url("../../assets/images/home/faq-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 150px!important;
  padding-bottom: 100px!important;
}

.notificationpage .title-page h2:after {
  left: 300px;
  width: calc(100% - 300px);
}

.notificationpage h3 {
  color: #fff;
  font-family: "Molot";
  font-size: 25px;
  position: relative;
  margin-top: 50px;
}

.notificationpage .table > thead > tr > th{
  padding: 10px 20px;
}

.notificationpage .home-table .table > tbody > tr > td {
  padding: 15px 20px;
}



.notificationpage .title-page {
  position: relative;
  padding-bottom: 50px;
}



.notificationpage .home-table .table > tbody > tr > td.winner {
  font-size: 20px;
  font-family: "Molot"!important;
  text-shadow: 0px 0px 10px #fff;
}

.notificationpage .title-page .red-btn {
  position: absolute;
  right: 0;
  top: 50%;
}

.notificationpage .home-table .table > tbody > tr > td .btn {
  padding: 0;
  color: #fff;
  letter-spacing: 1px;
  font-family: "Roboto-Light";
  border-bottom: 1px solid #3bbdf5;
  padding-bottom: 2px;
  text-transform: capitalize;
}

.notificationpage .home-table .table > tbody > tr > td .btn span {
  color: #3bbdf5;
}
.notificationpage .home-table .table > tbody > tr, .notificationpage .home-table .table > thead > tr {
  grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3)!important;
}