
.withdraw {
  padding: 75px 0px;
  /* background: #111 url("../../assets/images/home/legal-bg.jpg"); */
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

/* .withdraw:before {
content: "";
background: #0006;
position: absolute;
top: 0;
right: 0;
left: 0;
bottom: 0;
} */

.withdraw-content h2 {
  font-size: 35px;
  text-align: center;
  font-family: 'Molot';
  color: #fff;
}

.withdraw-content label {
  color: #fff;
  font-family: 'Roboto-Bold'!important;
}

.withdraw-content .form-group .form-control {
  background: transparent;
  border-radius: 3px;
  border: 1px solid #ffffff;
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
}

.withdraw-content .form-group .form-control::placeholder {
 color: #999;
}


.withdraw-content .form-group .form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #35abdf;
}


.withdraw-content input::-webkit-outer-spin-button,
.withdraw-content input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.withdraw-content {
  position: relative;
  max-width: 295px;
  margin: 0px auto;
}






form.withdraw-p {
  padding: 20px 0px;
}
.withdraw-p .alert-success::before {
  display: none;
}
.withdraw-p .alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.withdraw-p .alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.withdraw-p .alert-danger::before {
  display: none;
}
