
.tournament-page{
    background: url("../../assets/images/home/tournament-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 150px!important;
    padding-bottom: 50px!important;
}

.tournament-page .title-page h2:after {
    left: 300px;
    width: calc(75% - 300px)!important;
}

.tournament-page h3 {
    color: #fff;
    font-family: "Molot";
    font-size: 25px;
    position: relative;
    margin-top: 50px;
}

.tournament-page .table > thead > tr > th{
    padding: 10px 20px;
    text-align: center;
}

.tournament-page .home-table .table > tbody > tr > td {
    padding: 15px 20px;
    text-align: center;
}



.tournament-page .title-page {
    position: relative;
    padding-bottom: 0px;
}


.tournament-page .table > thead > tr > th:first-child {
    width: 35%;
    text-align: left!important;
}

.tournament-page .home-table .table > tbody > tr > td.winner {
    font-size: 20px;
    font-family: "Molot"!important;
    text-shadow: 0px 0px 10px #fff;
}
.tournament-page .home-table .table > tbody > tr > td:first-child{
   text-align: left!important;
    
}

.tournament-page .title-page .red-btn {
    position: absolute;
    right: 0;
    top: 50%;
}

.tournament-page .home-table .table > tbody > tr > td .btn {
    padding: 0;
    color: #fff;
    letter-spacing: 1px;
    font-family: "Roboto-Light";
    border-bottom: 1px solid #3bbdf5;
    padding-bottom: 2px;
    text-transform: capitalize;
}

.tournament-page .home-table .table > tbody > tr > td .btn span {
    color: #3bbdf5;
}

/***** write by ayushi ******/


.tournament-page .title-page h2:after {
    left: 300px!important;
    width: calc(75% - 300px)!important;
}
.finished-tournament .home-table .table > tbody > tr, .finished-tournament .home-table .table > thead > tr{
    /* border: 1px solid#3d4253; */
    display: grid;
    grid-template-columns:  calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5)!important;
    position: relative;
}