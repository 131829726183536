@import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css";

@import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css";

@import "https://fonts.googleapis.com/css?family=Passion+One:400,700&display=swap";

.stage-main {
  padding: 15px 0;
  overflow: hidden;
  position: relative;
}

.stage-main-background {
  background: #1f093a;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  z-index: 99;
}

.stage-main-video {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

video {
  min-width: 100%;
  overflow: hidden;
  z-index: -1;
}

.btn {
  font-weight: 400 !important;
  font-style: normal !important;
  font-family: "Poppins", sans-serif !important;
}

.refer-friend,
.popular-games {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.stage-main-overlay {
  background: #000000e3;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.stage-main-wrapper {
  padding: 30px 0px;
  position: relative;
  z-index: 999;
}

.stage-main-wrapper .row {
  align-items: center;
}
.stage-main-content {
  color: #fff;
  margin: 30px 0;
}

.stage-main-btn.slide-btn {
  background: #3bbdf5;
  border: 2px solid #3bbdf5;
  margin-right: 10px;
}

.stage-main-btn.slide-btn:hover {
  background: #8f1c1c;
  border: 2px solid #8f1c1c;
}

.stage-main-headline {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
  text-shadow: 0px 3px 5px #000;
  max-width: 630px;
  line-height: 1.4;
}

.stage-main-subline {
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  margin: 15px 0 15px;
}

.stage-main-text {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5;
  margin: 15px 0;
}

.stage-main-btn {
  margin-top: 50px;
  background: #8f1c1c;
  border: 2px solid #8f1c1c;
  color: #fff !important;
  border-radius: 50px;
  padding: 10px 30px;
  font-weight: 300;
  box-shadow: 0px 0px 10px #0000002b;
}

.stage-main-btn:hover,
.stage-main-btn:focus {
  background: #3bbdf5;
  border: 2px solid #3bbdf5;
  box-shadow: none;
  outline: none;
}

.how-works {
  background: url("../../assets/images/home/blue-bgg.jpg");
  padding: 100px 0px;
  background-size: cover;
  position: relative;
  height: 100%;
  /* background-attachment: fixed; */
}

.how-works:before {
  content: "";
  /*background: #3e757b66; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.how-work-box {
  text-align: center;
}

.how-work-box p {
  font-size: 15px;
}

.home-banner .card {
  border: none;
}

.how-work-box img {
  max-width: 201px;
  margin-bottom: 30px;
  transition: all 0.5s;
}

.how-work-box:hover img {
  transform: rotate(360deg);
}

.how-works .stage-main-btn {
  margin: 0px auto;
  display: inline-block;
  max-width: 200px;
  width: 100%;
  margin-top: 50px;
  background: #8f1c1c;
  border: 2px solid #8f1c1c;
  font-style: normal;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
}

.how-works .stage-main-btn:hover {
  background: #3bbdf5;
  border: 2px solid #3bbdf5;
}

.popular-games {
  padding: 100px 0px;
}

.page-title {
  text-align: center;
  padding-bottom: 50px;
  position: relative;
}

.page-title-dark {
  text-align: center;
  padding-bottom: 50px;
  position: relative;
}

.page-title h3:after {
  content: "";
  width: 100px;
  background: #8f1c1c;
  height: 4px;
  display: inline-block;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.page-title-dark h3:after {
  content: "";
  width: 100px;
  background: #8f1c1c;
  height: 4px;
  display: inline-block;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}
.popular-games-box img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.popular-games-box h3 {
  padding: 20px 10px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 12px 8px -6px #0000001c;
  -moz-box-shadow: 0 12px 8px -6px #0000001c;
  box-shadow: 0 12px 8px -6px #0000001c;
  color: #1c1c1c;
  font-size: 25px;
}

.popular-games-box h3 span {
  color: #8f1c1c;
}

.page-title h2 {
  font-size: 55px;
  color: #00000085;
  opacity: 0.1;
  margin: 0;
  line-height: 60px;
  text-transform: uppercase;
}

.page-title h3 {
  margin-top: -40px;
  font-size: 35px;
  text-transform: uppercase;
  z-index: 9;
  position: relative;
}

.page-title h3 span {
  color: #8f1c1c;
}

.page-title-dark h2 {
  font-size: 55px;
  color: #ffffff85;
  opacity: 0.1;
  margin: 0;
  line-height: 60px;
  text-transform: uppercase;
}

.page-title-dark h3 {
  margin-top: -40px;
  font-size: 35px;
  text-transform: uppercase;
  color: #fff;
  z-index: 9;
  position: relative;
}

.page-title-dark h3 span {
  color: #8f1c1c;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  font-family: "Poppins", sans-serif !important;
}

.tournament {
  background: url("../../assets/images/home/blue-bgg.jpg");
  padding: 100px 0px;
  background-size: cover;
  position: relative;
  height: 100%;
  /* background-attachment: fixed; */
}

.tournament:before {
  content: "";
  /*background: #3e757b66; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.card-headline strong {
  font-weight: 500;
}

.how-work-box h3 {
  font-size: 25px;
}

.tournament-block h3 {
  padding-bottom: 20px;
  color: #fff;
  font-size: 20px;
}
.how-tournament .stage-main-btn {
  margin: 0px auto;
  display: inline-block;
  max-width: 300px;
  width: 100%;
  margin-top: 50px;
  background: #8f1c1c;
  border: 2px solid #8f1c1c;
  font-style: normal;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
}
.how-tournament {
  padding: 0px 0px 100px 0px;
}
.how-tournament .stage-main-btn:hover {
  background: #3bbdf5;
  border: 2px solid #3bbdf5;
}

.tournament-block table thead tr th {
  background: #f5f7fa;
  border: 1px solid #d7d7d7;
  font-weight: bold;
  border-top: 1px solid #d7d7d7 !important;
  color: #333;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}

.tournament-block table tbody tr td {
  border: 1px solid #171717;
  color: #fff;
  font-weight: 100;
  background: #00000057;
  padding: 15px;
  vertical-align: middle;
}

.tournament-block table tbody tr td img {
  display: block;
  margin-left: -3px;
  margin-bottom: 5px;
}

.running-duels {
  padding: 100px 0px;
}

.avatar-sm .avatar-image-wrapper {
  height: 28px;
  width: 28px;
}

.avatar-image-wrapper {
  border-radius: 50%;
  overflow: hidden;
}

.avatar-image {
  width: 100%;
}

.running-duels .card {
  border: none;
}

.card {
  background: transparent;
  border: none;
  color: #101115;
  border-radius: 8px;
  display: block;
  margin: 0 0 15px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s;
}

.card-header {
  background: #8f1c1c;
  background-size: 120px auto;
  padding: 7.5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0;
}

.card-headline {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

strong {
  font-weight: 700;
}

.card-subline {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.card-badge {
  display: flex;
  justify-content: flex-end;
}
.badge-info {
  background-color: #1c1c1c;
}
.badge {
  border-radius: 4px;
  font-size: 10px;
  font-weight: 400;
  padding: 4.5px 7.5px 3.45px;
  text-transform: uppercase;
  color: #fff;
}

.card-body {
  align-items: stretch !important;
  border-left: 1px solid #dce2e6;
  border-right: 1px solid #dce2e6;
  display: flex;
  padding: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.card-body-item {
  align-items: stretch;
  background: #ebeef0;
  display: flex;
  padding: 0;
}

.card-body-item:first-child .card-body-item-inner {
  border-left: none;
}
.card-body-item-inner {
  border-left: 1px solid #fff;
  overflow: hidden;
  padding: 10.5px 15px;
  white-space: nowrap;
}

.card-body-headline {
  font-size: 16px;
}

.card-body-subline {
  color: #969caf;
  font-size: 10px;
  text-transform: uppercase;
}

.card-body-item-inner {
  border-left: 1px solid #fff;
  overflow: hidden;
  padding: 10.5px 15px;
  white-space: nowrap;
}

.card-footer:last-child {
  border-radius: 0;
}

.card-footer {
  background: #fff;
  border: 1px solid #dce2e6;
  border-top: none;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.vs-wrapper {
  align-items: stretch !important;
  display: flex;
  font-size: 14px;
  line-height: 1;
  position: relative;
  overflow: hidden;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.vs-item:first-child {
  justify-content: flex-end;
  padding-left: 15px;
}

.vs-item {
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 10px 0;
  position: relative;
  word-break: break-word;
  z-index: 120;
}

.vs-name {
  line-height: 1;
  margin: 0 3px;
  color: #333;
}

.avatar-sm {
  padding: 0;
}
.avatar {
  background: #fff;
  border: 2px solid #dce2e6;
  border-radius: 50%;
  display: inline-block;
  margin: 0 3px;
  position: relative;
}

.vs-vs {
  align-items: center;
  display: flex;
  justify-content: center;
}

.vs-vs:before {
  background: #fff;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  margin-left: -20px;
  position: absolute;
  top: 0;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
  transition: all 0.3s;
  width: 50%;
  z-index: 110;
}

.vs-vs span {
  color: #8f1c1c;
  font-size: 20px;
  font-weight: 900;
  font-style: italic;
  position: relative;
  z-index: 115;
}

.vs-vs:after {
  background: #fff;
  bottom: 0;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  left: 0;
  margin-left: -9px;
  position: absolute;
  top: 0;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: skew(-15deg, 0deg);
  transform: skew(-15deg, 0deg);
  transition: all 0.3s;
  width: 50%;
  z-index: 105;
}

.card-header .row {
  align-items: center;
}

.vs-item:last-child {
  justify-content: flex-start;
  padding-right: 15px;
}

.refer-friend {
  background: #171717;
  background: url("../../assets/images/home/main-bg.jpg");
  padding: 100px 0px;
  background-size: cover;
  position: relative;
  height: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.refer-friend:before {
  content: "";
  background: #0006;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.refer-bg {
  background-size: cover;
  height: 260px;
  background-repeat: no-repeat;
}
.refer-friend-content {
  max-width: 700px;
  position: relative;
  z-index: 99;
  text-align: center;
  margin: 0px auto;
}

.refer-friend-content h2 {
  margin-bottom: 30px;
  color: #fff;
}

.refer-friend-content p {
  margin-bottom: 0px;
  color: #f8f8f8;
  font-weight: 100;
}

.faqs {
  padding: 100px 0px;
}

.accordion {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: none !important;
  margin-top: 20px;
}
.accordion__item {
  position: relative;
  flex-basis: 49%;
  margin-bottom: 25px;
  border: none;
}

.accordion__button {
  transition: all 0.5s;
}

.accordion__button:hover {
  background-color: #8f1c1c !important;
  color: #fff;
}

.accordion__button:focus {
  outline: none;
}
.accordion__button:before {
  position: absolute;
  right: 5px;
  top: 25px;
  z-index: 9;
  opacity: 0.5;
}

.accordion__panel p {
  font-size: 14px;
  color: #777;
  font-weight: 100;
}

.refer-friend:before {
  content: "";
  background: url("../../assets/images/home/dotted-bg-pattern.png");
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.1;
  z-index: 9;
}

.accordion__item + .accordion__item {
  border-top: none !important;
}

.accordion__panel {
  border: 1px solid #efefef;
}

.shadow-effect {
  position: relative;
  overflow: inherit;
}
.shadow-effect:before,
.shadow-effect:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.shadow-effect:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}

.dark {
  background-color: #1c1c1c;
}
.popular-games .stage-main-btn {
  margin: 0px auto;
  display: inline-block;
  max-width: 150px;
  width: 100%;
  margin-top: 50px;
  background: #8f1c1c;
  border: 2px solid #8f1c1c;
  font-style: normal;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
}
.popular-games .stage-main-btn:hover {
  background: #3bbdf5;
  border: 2px solid #3bbdf5;
}
.popular-games {
  background: url("../../assets/images/home/blue-bgg.jpg");
  padding: 100px 0px;
  background-size: cover;
  position: relative;
  height: 100%;
  /* background-attachment: fixed; */

  padding: 100px 0px;
}
.popular-games:before {
  content: "";
  /*background: #3e757b66; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.running-duels .stage-main-btn {
  margin: 0px auto;
  display: inline-block;
  max-width: 150px;
  width: 100%;
  margin-top: 50px;
  background: #8f1c1c;
  border: 2px solid #8f1c1c;
  font-style: normal;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
}
.running-duels .stage-main-btn:hover {
  background: #3bbdf5;
  border: 2px solid #3bbdf5;
}
.running-duels {
  background: #111 url("../../assets/images/home/main-bg.jpg");
  padding: 100px 0px;
  background-size: cover;
  position: relative;
  height: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 100px 0px;
}
.running-duels:before {
  content: "";
  background: rgba(15, 11, 11, 0.4);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.tournament .stage-main-btn {
  margin: 0px auto;
  display: inline-block;
  max-width: 150px;
  width: 100%;
  margin-top: 50px;
  background: #8f1c1c;
  border: 2px solid #8f1c1c;
  font-style: normal;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
}
.tournament .stage-main-btn:hover {
  background: #3bbdf5;
  border: 2px solid #3bbdf5;
}

.faqs .stage-main-btn {
  margin: 0px auto;
  display: inline-block;
  max-width: 150px;
  width: 100%;
  margin-top: 50px;
  background: #8f1c1c;
  border: 2px solid #8f1c1c;
  font-style: normal;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
}
.faqs .stage-main-btn:hover {
  background: #3bbdf5;
  border: 2px solid #3bbdf5;
}

.gamedev {
  padding: 100px 0px;
}
.dev-heading {
  margin-bottom: 20px;
  color: #fff;
}
.game-heading {
  text-align: center;
}
.social-promo {
  padding: 100px 0px;
  padding-bottom: 0px;
}

.social-heading {
  text-align: center;
  margin-bottom: 50px;
}
.social-promo .box {
  border: none;
  width: 100%;
}
.social-block {
  display: inline-block;
  width: 49%;
}
.social-promo .hiw-page-wrapper {
  border-right: none;
}
.social-promo {
  background: url("../../assets/images/home/blue-bgg.jpg");
  padding: 100px 0px;
  background-size: cover;
  position: relative;
  height: 100%;
  /* background-attachment: fixed; */
}

.social-promo i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
  border-radius: 100%;
  margin: 15px 10px;
  color: #3bbdf5;
  transition: all 0.5s;
  cursor: pointer;
}

.social-promo i:hover {
  background: #3bbdf5;
  color: #fff;
}

.social-promo:before {
  content: "";
  /*background: #3e757b66; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.general-promo {
  background: #111 url("../../assets/images/home/main-bg.jpg");
  padding: 100px 0px;
  background-size: cover;
  position: relative;
  height: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 100px 0px;
  padding-bottom: 20px;
}
.general-promo:before {
  content: "";
  background: rgba(15, 11, 11, 0.4);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.general-block {
  display: inline-block;
  width: 49%;
}
.general-promo .box {
  border: none;
  width: 100%;
}
.general-promo .box-content {
  background: none;
}
.general-block h4 {
  color: #fff;
  font-family: "Poppins", sans-serif !important;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.general-promo .hiw-page-content {
  padding: 0px;
}
.general-promo .hiw-page-wrapper {
  border-right: none;
}
.general-c i.fa {
  color: #fff;
  font-size: 40px;
  background: #8f1c1c;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 100%;
  margin-bottom: 20px;
  transition: all 0.5s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
.general-c i.fa:hover {
  background: #3bbdf5;
  color: #fff;
  cursor: pointer;
  animation-name: bounce;
  -moz-animation-name: bounce;
}

.hiw-page-content.general-block {
  padding-bottom: 80px;
}
.how-tournament:before {
  content: "";
  background: #0006;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.form-label {
  font-style: normal;
}

.how-tournament {
  padding: 100px 0px;
  background: #111 url("../../assets/images/home/main-bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.how-tournament-icon img {
  margin: 0px auto;
}

.how-tournament .hiw-text {
  color: #fff;
}
.how-tournament .hiw-headline {
  color: #fff;
}
.how-tournament .box-content {
  background: transparent;
}
.how-tournament .box {
  border: none;
}
.how-tournament .hiw-page-wrapper {
  border-right: none;
}
.bonus-money-promo {
  padding: 100px 0px;
}
.bonus-pro {
  text-align: center;
}
.social-promo .box-content {
  background: none;
}

.login-btn:hover {
  background-color: #3bbdf5;
  border-color: #3bbdf5;
  box-shadow: 0px 0px 50px 0px #ffffff9c;
}
.signup-btn:hover {
  background-color: #e00033;
  border-color: #e00033;
  box-shadow: 0px 0px 50px 0px #ffffff9c;
}

img.soci-media {
  padding: 20px;
}
.social-promo .hiw-headline {
  font-size: 22px;
}
.social-promo .hiw-text {
  margin-top: 20px;
  font-weight: 300;
}

.gamedev {
  background: #111 url("../../assets/images/home/main-bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.gamedev:before {
  content: "";
  background: #0006;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.gamedev .page-title h2 {
  color: #ffffff85;
}
.gamedev .page-title h3 {
  color: #fff;
}
.cont-uv {
  text-align: center;
  padding-top: 40px;
}
.game-heading p {
  color: #fff;
}
.get-h {
  color: #fff;
}

.hiw-page-content:hover .hiw-image {
  transform: rotate(360deg);
}

.hiw-page-content .hiw-image {
  transition: all 0.5s;
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.slick-prev::before,
.slick-next::before {
  font-family: "FontAwesome" !important;
}
.slick-prev::before {
  content: "\f104" !important;
  font: normal normal normal 14px/1 FontAwesome;
}

.slick-next::before {
  content: "\f105" !important;
  font: normal normal normal 14px/1 FontAwesome;
}

.slick-prev,
.slick-next {
  top: 50% !important;
  transform: translateY(-50%) !important;
  background-color: #3bbdf5 !important;
}

.slick-prev {
  left: 10px !important;
  right: inherit !important;
}

.slick-next {
  right: 10px !important;
}

.slick-dots li.slick-active button {
  background-color: #3bbdf5 !important;
}

.slick-slide img.flag {
  position: absolute;
  top: 20px;
  width: 17px;
  right: -10px;
}

.slick-slide img.flag.f-right {
  left: -10px;
  right: inherit;
}

.avatar-flag img.flag {
  position: absolute;
  top: 20px;
  width: 17px;
  right: -10px;
}

.avatar-flag img.flag.f-right {
  left: -10px;
  right: inherit;
}

.running-duels .card-header {
  background: #8f1c1c;
}

.running-duels .vs-vs span {
  color: #8f1c1c;
}

.slide-block .col-md-12.col-lg-12:nth-child(2) .card-header {
  background: #3bbdf5;
}

.slide-block .col-md-12.col-lg-12:nth-child(2) .vs-vs span {
  color: #3bbdf5;
}

.modal45 .modal-content {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: none;
}
.modal45 .modal-header .close {
  margin-top: -17px;
}
.modal45 .modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modal45 .modal-footer {
  padding: 13px;
}
.modal45 .modal-content {
  width: 80%;
}
.modal45 .modal-title h4 {
  font-size: 10px !important;
}
div#example-custom-modal-styling-title {
  font-size: 20px !important;
}
.cust14.btn {
  margin-top: 45px;
  font-size: 16px;
  padding: 0px 13px;
  color: #fff;
  /* border: 1px solid #fff; */
  border-radius: 12px;
  background-color: black;
  margin-left: 30px;
  box-shadow: 0px 0px 6px 3px #ece1e1ba;
}
.cust14.btn h6 {
  font-size: 13px;
}

/************ Match Making Popup *************/

.match-making-popup .modal-dialog {
  max-width: 900px;
  width: 100%;
}
.wrapperr h1 {
  font-size: 120px;
  font-family: "Molot";
  text-shadow: 0px 0px 10px #0e8fcaad;
  color: #07102d;
}

.mobile-nav.mobile {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 99999;
  background: #0d111e;
}
.loader h4 {
  color: #fff;
  font-weight: 500;
  margin-bottom: 25px;
  font-family: "Molot";
  letter-spacing: 0.5px;
  font-size: 25px;
}

.match-making-popup-content .loader {
  padding-top: 70px;
}

.loader .progress {
  background-color: transparent;
  height: 10px;
  border-radius: 10px;
  border: 1px solid #fff;
}

.loader .progress-bar {
  background-color: #fff;
}
.sdf {
  /* text-align: left!important; */
  margin-top: 20px;
}
.dfs {
  text-align: center !important;
  margin-top: 20px;
}
.dfs img {
  height: 125px;
}

.match-making-popup .modal-header .close {
  margin-top: 0;
  position: absolute;
  right: 20px;
  top: 0;
}

.match-making-popup .modal-header {
  justify-content: center;
  border-bottom: none;
  padding: 50px 15px;
  padding-bottom: 0;
}

.match-making-popup .modal-title {
  font-size: 50px;
  font-family: "Roboto-Light";
}

.match-box .player-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: center;
}

.match-box {
  text-align: center;
}

.match-box h4 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  padding-top: 20px;
  display: block;
  color: #9d9fa6;
  letter-spacing: 1px;
  font-family: "Molot";
}

.match-heading {
  color: #d4d3d3;
  text-align: center;
  max-width: 500px;
  margin: 0px auto;
  font-size: 20px;
  font-family: "Molot";
  letter-spacing: 0.5px;
}

.user-pics {
  position: relative;
  max-width: 150px;
  margin: 0px auto;
}

.flag-pic {
  width: 25px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.vs {
  max-width: 150px;
  margin: 0px auto;
}

.vs img {
  width: 100%;
}

.match-making-popup-content .row {
  align-items: center;
}

.match-making-popup .modal-content {
  background: #050c1b url(../../assets/images/home/gameloading.png);
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 500px;
}

.interval {
  text-align: center;
}

.interval p {
  margin-bottom: 10px;
}

.match-making-popup-content.modal-body {
  padding: 20px 40px;
}

.link-go {
  background: #3bbdf5;
  display: inline-block;
  color: #fff;
  padding: 7px 30px;
  border-radius: 20px;
  font-size: 15px;
  cursor: pointer;
}

.match-making-popup button.close:focus {
  outline: none;
}

.match-making-popup .close {
  float: right;
  font-size: 2rem;
  font-weight: 100;
  line-height: 1;
  color: #fff;
  text-shadow: none;
  opacity: 0.5;
}

.match-making-popup .close:hover {
  color: #fff;
  text-decoration: none;
}

.estimated-time {
  text-align: center;
  font-family: "Molot";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000ba;
  z-index: 9;
}

.estimated-time p {
  top: 50%;
  display: inline-block;
  color: #fff;
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../assets/images/home/refresh.png");
  background-size: cover;
  width: 150px;
  height: 150px;
  line-height: 150px;
  background-repeat: no-repeat;
}

.estimated-time span {
  letter-spacing: 0.5px;
  font-size: 50px;
}

.next-link {
  color: #fff;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: "Roboto-Light";
  border-bottom: 1px solid #3bbdf5;
  padding-bottom: 2px;
  float: right;
  position: absolute;
  right: 61%;
}

.next-link:hover,
.prev-link:hover {
  color: #fff;
}

.next-link:focus,
.prev-link:focus {
  color: #fff;
}

.next-link span,
.prev-link span {
  color: #3bbdf5;
}

.prev-link {
  color: #fff;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: "Roboto-Light";
  border-bottom: 1px solid #3bbdf5;
  padding-bottom: 2px;
  float: left;
  position: absolute;
  left: 41%;
}

.slick-prev,
.slick-next {
  display: none !important;
}

/********* Game Table ***********/

.game-head {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  font-size: 13px;
  padding: 5px 10px;
  font-family: "Roboto-Bold" !important;
}

.table-game {
  width: 20%;
  position: relative;
  padding-right: 20px;
}

.table-gamer {
  width: 35%;
  text-align: center;
}
.table-platform {
  width: 15%;
  text-align: center;
}

.table-entry {
  width: 15%;
  text-align: center;
}

.table-prize {
  width: 15%;
  text-align: center;
}

.game-list-view {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #fff;
  font-size: 13px;
  border: 1px solid #ffffff2b;
  margin: 15px 0px;
  padding: 5px 10px;
  border-radius: 3px;
  font-family: "Roboto-Bold" !important;
  position: relative;
}

.game-list-view .table-prize {
  font-size: 18px;
}

.table-game a {
  color: #fff;
  position: absolute;
  right: 10px;
  top: 0px;
}

.game-list-view::before {
  content: "";
  background: rgb(2, 7, 18);
  background: linear-gradient(
    90deg,
    rgba(2, 7, 18, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(2, 7, 18, 0) 100%
  );
  height: 1px;
  width: 50%;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
}

.game-list-view::after {
  content: "";
  background: rgb(2, 7, 18);
  background: linear-gradient(
    90deg,
    rgba(2, 7, 18, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(2, 7, 18, 0) 100%
  );
  height: 1px;
  width: 50%;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 1440px) {
  .how-works,
  .social-promo,
  .popular-games,
  .tournament {
    background: url("../../assets/images/home/blue-bgg-sm.jpg");
    background-size: cover;
    background-attachment: fixed;
  }
}

@media screen and (max-width: 991px) {
  .accordion__item {
    flex-basis: 100%;
  }
}

.flag-img {
  box-shadow: 0px 1px 2px #0000005c;
  width: 30px;
  position: absolute;
  bottom: 10px;
  right: 6px;
}
.player-img {
  position: relative;
  border-radius: 50%;
}

@media screen and (max-width: 767px) {
  .popular-games {
    padding: 20px 0px;
  }

  .running-duels {
    padding: 20px 0px;
  }
  .general-promo {
    padding: 20px 0px;
  }

  .gamedev {
    padding: 20px 0px;
  }
  .faqs {
    padding: 100px 0px;
  }
  .payment {
    top: 43px;
  }
  .general-block {
    vertical-align: top;
  }
  .how-works {
    padding: 40px 0px;
  }
  .how-tournament {
    padding: 40px 0px;
  }
  .social-promo {
    padding: 40px 0px;
  }
  .tournament {
    padding: 40px 0px;
  }

  .how-works .stage-main-btn {
    width: 45%;
  }
}
@media all and (max-width: 1024px) and (min-width: 768px) {
  .general-block {
    vertical-align: top;
  }
  ul.footer-list li img {
    width: 16px;
    margin-right: 3px;
  }
}

/* POPUP CSS */

.wrapperr {
  position: relative;
}

.wrapperr .vs {
  max-width: 20%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 65%);
  z-index: 9;
}
.bg857 {
  background-position: center;
  border-radius: 50%;
  margin-top: 20px;
  padding: 0;
}

.match-making-popup .modal-title.h4 {
  font-size: 17px;
  text-align: center;
  color: #fff;
  font-family: "Roboto-Light" !important;
  max-width: 528px;
  line-height: 1.5;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
    transform: scale(0);
  }
  50% {
    background-position: 400% 0;
    transform: scale(1.1);
  }
  100% {
    background-position: 0 0;
    transform: scale(0);
  }
}

.link-go:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0e1427;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.link-go:before {
  content: "";
  background: linear-gradient(
    90deg,
    rgb(13 241 241 / 46%) 0%,
    rgb(37 198 206 / 28%) 50%,
    rgb(98 216 216 / 56%) 100%
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(2px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 1s linear infinite;
  opacity: 1;
  transition: opacity 0.3s ease-in;
  border-radius: 10px;
}

p.link-go {
  position: relative;
  z-index: 9;
  margin-top: 20px;
  min-width: 145px;
}

.notification-error {
  /* background-color: #8f1c1c !important; */
  border: 1px solid #e30b0b !important;
  background-color: #0b0e21 !important;
}

.notification-success {
  /* background-color: #3bbdf5 !important; */
  background-color: #0b0e21 !important;
}
/* *** */

/*******write by ayushi ****/

.table-game img {
  width: 19px;
  transition: all 0.5s;
}

.table-game img:hover {
  transform: scale(1.3);
}

.table-game img {
  width: 19px;
  transition: all 0.5s;
  /* position: relative; */
  position: initial;
  z-index: 9;
}

.table-game img:hover {
  transform: scale(1.3);
}

.table-game a::before {
  content: "";
  width: 20px;
  height: 20px;
  background: #ffffff;
  display: block;
  position: absolute;
  filter: blur(10px);
}

/***** by Ayushi ***/
.home-text {
  padding-top: 30px !important;
}

.loader-bar {
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  border: 4px solid transparent;
  position: relative;
  padding: 4px;
  max-width: 330px;
}
.loader-bar:before {
  content: "";
  border: 1px solid #fff;
  border-radius: 10px;
  position: absolute;
  top: -1px;
  right: -4px;
  bottom: -1px;
  left: -4px;
}
.loader-bar .loaderBar {
  position: absolute;
  border-radius: 10px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 0;
  animation: borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}

.bet-popup .dropdown-toggle {
  background: transparent;
  border: 1px solid #fff;
  width: 100%;
  text-align: left;
  padding: 15px 20px;
  text-transform: inherit;
  border-image-slice: 1 !important;
  border-width: 1px !important;
  border-image-source: linear-gradient(
    to left,
    #3d4253,
    #ffffff,
    #3d4253
  ) !important;
}

.bet-popup .dropdown-toggle:focus {
  background: transparent !important;
  border: 1px solid #fff !important;
  outline: none !important;
  box-shadow: none;
}

.bet-popup .dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 20px;
}

.bet-popup .show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: #fff;
}

.bet-popup .dropdown-menu {
  background: #05020f3d;
  width: 100%;
  border: 1px solid #949494;
  margin-top: 0px;
  position: relative !important;
  transform: inherit !important;
  margin-bottom: 20px;
}

.bet-popup .dropdown-item:hover {
  background: #091e3ed1;
  color: #fff;
}

.bet-popup .dropdown-item {
  font-size: 12px;
  color: #fff;
  padding: 5px 15px;
}

.bet-popup .dropdown-toggle:hover {
  background-color: transparent;
  border-color: #3bbdf5;
}

.bet-popup .dropdown-toggle::after {
  background: url(../../assets/images/header/select-arrow.png);
  content: "";
  border: none;
  display: block;
  width: 18px;
  height: 9px;
  background-size: cover;
}

.bet-popup .show .dropdown-toggle::after {
  transform: rotate(180deg);
}

.sdf img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.speed {
  color: #d4d3d3;
  text-align: center;
  max-width: 520px;
  margin: 0px auto;
  font-size: 12px;
  padding-top: 5px;
}

.loading {
  -webkit-animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotatey(0);
  }
  to {
    -webkit-transform: rotatey(360deg);
  }
}
.soon img {
  object-fit: cover;
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.loading-image {
  width: 123px;
  height: 123px;
  border: 1px solid #3d4253;
}
.userimg2 {
  height: 125px;
}
.sdf h4 {
  /* margin-left: 10px; */
  text-align: center;
  color: white !important;
}
.dfs h4 {
  /* margin-right: 15px; */
  color: white !important;
}
.loading-image:before {
  content: "";
  background: rgb(2, 7, 18);
  background: linear-gradient(
    90deg,
    rgba(2, 7, 18, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(2, 7, 18, 0) 100%
  );
  height: 1px;
  width: 29%;
  position: absolute;
  top: 20px;
  left: 48%;
  transform: translateX(-50%);
  display: block;
}
.loading-image:after {
  content: "";
  background: rgb(2, 7, 18);
  background: linear-gradient(
    90deg,
    rgba(2, 7, 18, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(2, 7, 18, 0) 100%
  );
  height: 1px;
  width: 29%;
  position: absolute;
  bottom: 56px;
  left: 48%;
  transform: translateX(-50%);
  display: block;
}
.match-making-popup-content .col-3 {
  display: flex;
  justify-content: center;
}
.popular-game-section .popular-one,
.popular-game-section .popular-two,
.popular-game-section .popular-three,
.popular-game-section .popular-four {
  padding: 0px 5px;
}
.popular-game-section .popular-one {
  padding-left: 15px !important;
}
.popular-game-section .popular-four {
  padding-right: 15px !important;
}

.home-game-table .game-list-view:nth-child(2) .table-game a img,
.home-game-table .game-list-view:nth-child(3) .table-game a img {
  top: 0px;
  position: relative;
}
.align-tournament {
    margin-top: 400px !important;
}