.tournamentdetails .card-body {
  display: block;
  padding: 1.25rem;
  border: none;
  font-size: 13px;
}

.tournamentdetails .card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  background-color: #ffffff;
}

.tournamentdetails {
  background: #f2f3f8;
  padding: 50px 0px;
}

.ui-w-100 {
  width: 100px;
  height: auto;
  margin: 0px auto;
}

.tournamentdetails.card-footer {
  border: none;
}

.tournamentdetails .tournament-block table tbody tr td {
  border: 1px solid #f7f7f7;
  color: #1c1c1c;
  font-weight: 100;
  background: #ffffff;
  padding: 15px;
  vertical-align: middle;
}

.profile .tournament-block table tbody tr td {
  border: 1px solid #f7f7f7;
  color: #1c1c1c;
  font-weight: 100;
  background: #ffffff;
  padding: 15px;
  vertical-align: middle;
}
.game-name {
  text-align: center;
  padding-bottom: 50px;
}
.details-tour {
  text-align: center;
  padding-bottom: 30px !important;
}
