.register-section {
  padding: 75px 0px;
  /* background: #111 url("../../assets/images/home/legal-bg.jpg"); */
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  display: flex;
    align-items: center;
}
/* .register-section:before {
  content: "";
  background: #0006;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
} */

.dis-show {
  display: flex;
  margin-bottom: 10px!important;
}

.dis-show label.form-label {
  font-size: 12px;
  padding-left: 10px;
  color: #d0d0d0;
  font-family: 'Roboto-Regular'!important;

}

.register-center{

  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;

}
.register-form {
  max-width: 700px;
  margin: 0px auto;
}

.register-form h1 {
  font-size: 35px;
  text-align: center;
  font-family: 'Molot';
  color: #fff;
}

.register-form label {
  color: #fff;
  font-family: 'Roboto-Bold'!important;
}


.rigister-tab .form-group .form-control {
  background: transparent;
  border-radius: 3px;
  border: 1px solid #ffffff;
  padding: 10px 20px;
  font-size: 14px;
  color: #999;
}

.rigister-tab .form-group .form-control::placeholder {
  color: #999;
 }


 .terms-link {
  color: #fff;
  font-size: 11px;
  letter-spacing: 1px;
  font-family: "Roboto-Light";
  border-bottom: 1px solid #3bbdf5;
  padding-bottom: 2px;
  margin: 0px 5px;
}

.terms-link:hover{
    color: #fff;
}

.form-check {
    display: inline-block;
}


.form-check-input {
  width: 15px;
  height: 15px;
  border: 1px solid #fff;
  -webkit-appearance: none;
  margin-top: 0;
}

.form-check-input:focus {
  outline: none;
}


.form-check-input:checked:after {
  content: "\2713";
  color: #fff;
  display: block;
  font-size: 20px;
  position: absolute;
  top: -9px;
  left: 2px;
}

.s-btn.btn.btn-primary {
    padding: 12px 40px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    border-radius: 40px;
    background: #8f1c1c;
    border: #8f1c1c;
}

.s-btn.btn.btn-primary:hover, .s-btn.btn.btn-primary:focus, .s-btn.btn.btn-primary:active {
    background: #1c1c1c;
    border: #1c1c1c;
    box-shadow: none;
}

.form-label {
  font-style: normal;
}




.social-login {
  text-align: center;
  padding: 30px 0px;
}

.loginBtn {
    box-sizing: border-box;
    position: inline;
    /* width: 13em;  - apply for fixed size */
    margin: 0.2em;
    padding: 14px 50px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 13px;
    color: #FFF;
  }
  .loginBtn:before {
    content: "";
    box-sizing: border-box;
    position: inline;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
  }
  .loginBtn:focus {
    outline: none;
  }
  .loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
  }
  
  
  /* Facebook */
  .loginBtn--facebook {
    background-color: #4C69BA;
    background-image: linear-gradient(#4C69BA, #3B55A0);
    /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
    text-shadow: 0 -1px 0 #354C8C;
  }
  .loginBtn--facebook:before {
    border-right: none!important; 
    background: url('../../assets/images/header/facebook-icon.png') 6px 6px no-repeat;
  }
  .loginBtn--facebook:hover,
  .loginBtn--facebook:focus {
    background-color: #5B7BD5;
    background-image: linear-gradient(#5B7BD5, #4864B1);
  }
  
  
  /* Google */
  .loginBtn--google {
      float: none;
    /*font-family: "Roboto", Roboto, arial, sans-serif;*/
    background: #DD4B39;
  }
  .loginBtn--google:before {
    border-right: none;
    background: url("../../assets/images/header/google-icon.png") no-repeat;
    width: 21px;
    height: 22px;
    background-size: cover;
    position: absolute;
    left: 10px;
    top: 5px;
    padding-right: 5px;
  }
  .loginBtn--google:hover,
  .loginBtn--google:focus {
    background: #E74B37;
  }








  .register-form .alert-success {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.05);
    padding: 13px;
    padding-left: 13px;
}

 .register-form .alert-success::before{
    display: none;
  }


  .rigister-tab .dropdown-toggle {
    background: transparent;
    border: 1px solid #fff;
    width: 100%;
    text-align: left;
    padding: 15px 20px;
    text-transform: inherit;
    border-image-slice: 1!important;
    border-width: 1px!important;
    border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253)!important;
}


.rigister-tab .dropdown-toggle:focus {
    background: transparent!important;
    border: 1px solid #fff!important;
    outline: none!important;
    box-shadow: none;
}


.rigister-tab .dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 20px;
}

.rigister-tab .show>.btn-primary.dropdown-toggle{
  background-color: transparent;
  border-color: #fff;
}


.rigister-tab .dropdown-menu {
  background: #05020f3d;
  width: 100%;
  border: 1px solid #949494;
  margin-top: 0px;
  position: relative!important;
  transform: inherit!important;
  margin-bottom: 20px;
}

.rigister-tab .dropdown-item:hover {
  background: #091e3ed1;
  color: #fff;
}

.rigister-tab .dropdown-item {
  font-size: 12px;
  color: #fff;
  padding: 5px 15px;
}


.rigister-tab .dropdown-toggle:hover {
  background-color: transparent;
  border-color: #3bbdf5;
}

.rigister-tab .dropdown-toggle::after {
  background: url(../../assets/images/header/select-arrow.png);
  content: "";
  border: none;
  display: block;
  width: 18px;
  height: 9px;
  background-size: cover;
}


.rigister-tab .show .dropdown-toggle::after {
  transform: rotate(180deg);
}
.rigister-tab .css-yk16xz-control {
  background: transparent;
    border-radius: 3px;
    border: 1px solid #ffffff;
    padding: 5px 10px;
    font-size: 14px;
    color: #999;
    border-image-slice: 1!important;
    border-width: 1px!important;
    border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253)!important;
}
