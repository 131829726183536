.navbar {
  padding: 0.5rem 0rem;
}

header {
  position: absolute;
  width: 100%;
  top: 0;
}

.logo img {
  max-width: 214px;
}

.login-btn {
  background-color: #3bbdf5;
  border-color: #3bbdf5;
  margin-right: 10px;
  font-size: 13px;
  color: #fff;
  padding: 10px 30px;
  text-decoration: none;
  border-radius: 3px;
  font-weight: 500;
  transition: all 0.5s;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.btn-primary:focus,
.btn-primary.focus {
  background-color: #3bbdf5 !important;
  border: 2px solid #3bbdf5 !important;
}

.signup-btn {
  margin-right: 10px;
  font-size: 13px;
  padding: 10px 30px;
  text-decoration: none;
  border-radius: 3px;
  font-weight: 500;
  transition: all 0.5s;
  background-color: #E00033;
  border-color: #E00033;
  box-shadow: none;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.login-btn:hover,
.login-btn:focus {
  color: #fff;
  text-decoration: none;
}

.signup-btn:hover,
.signup-btn:focus {
  color: #fff;
  text-decoration: none;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  font-size: 13px;
  padding-right: 0;
  padding-left: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0px 1rem;
  border-bottom: 2px solid transparent;
  font-family: 'Roboto-Light';
}

.navbar-light .navbar-nav .nav-link.active {
  color: #fff;
  border-bottom: 2px solid #3bbdf5;
}

.navbar-light .navbar-nav .nav-link:hover {
  border-bottom: 2px solid #3bbdf5;
}

.navbar-brand {
  margin-right: 30px;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color:#fff;
}

.p-pic {
  max-width: 40px;
}


.my-profile-menu {
  display: flex;
  margin-left: 15px;
  position: relative;
  align-items: center;
}

.p-name {
  padding-left: 10px;
  position: relative;
}

.p-name h5 {
  font-weight: 400;
  color: #fff;
  font-size: 12px;
  margin-bottom: 0;
  text-transform: capitalize;
  letter-spacing: 1.5px;
  border-bottom: 1px solid #019acc;
  padding-bottom: 2px;
}

.p-name span {
  color: #696969;
  font-size: 12px;
}

.p-name .dropdown .btn {
  padding: 5px;
  line-height: 0;
  background: transparent;
}

.my-profile-menu .dropdown {
  position: absolute;
  display: inline-block !important;
  vertical-align: middle;
  right: -20px;
  top: -5px;
}

.my-profile-menu .dropdown button:hover {
  background: transparent;
  border-color: transparent;
}

.my-profile-menu .dropdown button:focus {
  background: transparent;
  border-color: transparent;
  outline: none;
  box-shadow: none;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
  color: #fff;
}

.dropdown-item {
  font-size: 11px;
  letter-spacing: 1px;
}

.dropdown-menu {
  min-width: 8rem;
  right: 0;
  left: inherit;
}

.wallet-container {
  display: flex;
  flex-wrap: wrap;
}

.wallet-box span {
  display: block;
  color: #fff;
  font-size: 12px;
  font-family: 'Roboto-Light';
}

.money-wallet-box img {
  /* width:40px; */
  width: 30px;
}

.wallet-box {
  margin: 0px 5px;
  color: #fff;
  display: flex;
  align-items: center;
}

.bonus-wallet img {
  /* width: 40px; */
  width: 30px;
}

.head-user-info {
  display: flex;
  align-items: center;
}

.wallet-box i {
  font-style: normal;
  color: #fff;
  font-family: 'Roboto-Bold';
}

.wallet-box h4 {
  font-size: 12px;
  font-family: 'Roboto-Bold';
  margin: 0;
  margin-right: 10px;
}

.wallet-box h5 {
  font-size: 12px;
  font-family: 'Roboto-Bold';
  margin: 0;
  margin-right: 5px;
}
.money-wallet-box {
  padding: 0px 5px;
}

.money-wallet {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.money-wallet-box p {
  margin: 0;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
}

.money-wallet-box p span {
  font-weight: 500;
  display: inline-block;
  color: #fff;
}

span.ac-banned.badge.badge-danger {
  display: block;
  width: 100%;
}
span.ac-notification.badge.badge-primary {
  margin-left: 20px;
  display: none;
}


.my-profile-menu .dropdown-menu {
  background: rgb(2,7,18);
  background: linear-gradient(180deg, rgba(2,7,18,1) 0%, rgb(2 7 18 / 98%) 100%);
  border: 1px solid #019acb;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient( to bottom, #019acb, rgba(0, 0, 0, 0) ) 1 100%;
}

.my-profile-menu .dropdown-menu .dropdown-item {
  color: #9c9c9c;
  text-align: center;
}

.my-profile-menu .dropdown-menu .dropdown-item:hover {
  color: #fff;
  background: transparent;
}


.my-profile-menu .dropdown-menu .dropdown-item:focus {
  background: transparent;
}

.my-profile-menu .dropdown-menu::before {
  content: "";
  width: 100%;
  background: #019acb;
  height: 1px;
  display: block;
  position: absolute;
  top: -1px;
}

.p-pic {
  position: relative;
}

.star-icon {
  position: absolute;
  right: -4px;
  top: -4px;
}
.profile-image{
  height: 22px;
    width: 22px;
    border-radius: 3px;
}
