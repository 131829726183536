
.deposit {
  padding: 75px 0px;
  /* background: #111 url("../../assets/images/home/legal-bg.jpg"); */
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

/* .deposit:before {
content: "";
background: #0006;
position: absolute;
top: 0;
right: 0;
left: 0;
bottom: 0;
} */

.deposite-content h2 {
  font-size: 35px;
  text-align: center;
  font-family: 'Molot';
  color: #fff;
}

.deposite-content label {
  color: #fff;
  font-family: 'Roboto-Bold'!important;
}

.deposite-content .form-group .form-control {
  background: transparent;
  border-radius: 3px;
  border: 1px solid #ffffff;
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  padding-left: 30px;
}
.deposite-content .input-group-text {
  border-image-slice: 1!important;
  border-width: 1px!important;
  border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253)!important;
  background-color: transparent;
}

/* .deposite-content .form-group::before {
  content: "$";
  color: #fff;
  position: absolute;
  top: 47px;
  left: 15px;
} */

.deposite-content .form-group {
  position: relative;
}


.deposite-content .form-group .form-control::placeholder {
 color: #999;
}


.deposite-content .form-group .form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #35abdf;
}


.deposite-content input::-webkit-outer-spin-button,
.deposite-content input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.deposite-content {
  position: relative;
  max-width: 275px;
  margin: 0px auto;
}



form.deposit-p {
  padding: 20px 0px;
}
.deposit-p .alert-success::before {
  display: none;
}

.deposit-p .alert-danger {
  color: #fff;
  background: transparent;
  border-color:transparent;
  padding: 10px 20px;
}


.deposit-p .alert-danger::before {
  display: none;
}


.deposit-p .alert-success {
  background: transparent;
  border-color:transparent;
  padding: 10px 20px;
}

.deposit-p .alert .h4 {
  font-family: "Molot";
  color: #fff;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0;
  position: relative;
}

.deposit-p .alert-success .h4::after{
  content: "";
background: url("../../assets/images/home/flare_blue.png");
width: 214px;
height: 37px; 
background-size: cover;
background-repeat: no-repeat;
display: block;
}

.deposit-p .alert-danger .h4::after{
content: "";
background: url("../../assets/images/home/flare_red.png");
width: 214px;
height: 37px; 
background-size: cover;
background-repeat: no-repeat;
display: block;
}