.footer {
    background: #0d111e;
}

.copy-right {
    text-align: left;
    border-top: 1px solid #2a2e3a;
    padding: 15px 0px;
    font-weight: 100;
}

.copy-right p {
    color: #3b404e;
    margin: 0;
    font-size: 12px;
    letter-spacing: 1.5px;
    font-family: 'Roboto-Light';
}

.top-footer {
    background: #0d111e;
    padding: 40px 0px;
}

.top-footer h4 {
    color: #fff;
    margin-bottom: 25px;
    font-size: 14px;
    letter-spacing: 1.5px;
    font-family: "Roboto-Medium";
}

.top-footer p {
    color: #fff;
    font-size: 12px;
    font-weight: 100;
    line-height: 1.7;
    max-width: 320px;
    letter-spacing: 1.5px;
    font-family: 'Roboto-Light';
 

}

.top-footer .footer-one img {
    margin-bottom: 20px;

} 

ul.footer-list {
    padding-left: 0px;
    list-style-type: none;
}

ul.footer-list li {
    padding: 8px 0px;
}

ul.footer-list li a {
    font-size: 13px;
    font-weight: 300;
    color: #fff;
    letter-spacing: 1.5px;
    padding-bottom: 4px;
    font-family: 'Roboto-Light';
}

ul.footer-list li a:hover {
    color: #fff;
    text-decoration: none;
    border-bottom: 2px solid #3bbdf5;
}



ul.footer-list li img {
    width: 20px;
    margin-right: 3px;
}

.payment{
    width: 100px;
    position: absolute;
    top: 15px;
    right: 0;
}

.copy-right{
    position: relative;
}

.copy-right span {
    position: absolute;
    left: 0;
    color:#ccc;
}

.sponsors-box {
    border-bottom: 1px solid #2a2e3a;
    padding-bottom: 20px;
}

.sponsors-box .sponser-item{
 width: 20%;
}

.sponsors-container {
    background: #0d111e;
    padding-top: 20px;
}

.sponsors-box .sponser-item img {
    transition: all 0.5s;
    cursor: pointer;
    margin: 0px auto;
    max-width: 150px;
    filter: grayscale(100%) brightness(0.5);
    }
    .sponsors-box .sponser-item img:hover {
    filter: none!important;
    }

.play-store ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
}

.play-store img {
    max-width: 120px;
    width: 100%;
    margin-right: 5px;
    border: 1px solid #777;
    border-radius: 5px;
}

.sponsors-container ul.slick-dots {
    display: none!important;
}
/* .sponsors-box  .slick-slider .slick-list .slick-slide:focus{
    outline: none!important;
    border: none!important;
    box-shadow:none!important;
}
.sponsors-box .sponser-item img:focus {
    outline: none!important;
} */
.slick-slide > div:focus, .sponsors-box .sponser-item:focus {
    outline: none!important;
    }