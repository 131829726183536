.forget-section {
    padding: 75px 0px;
    /* background: #111 url("../../assets/images/home/legal-bg.jpg"); */
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

/* .forget-section:before {
  content: "";
  background: #0006;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
} */


.changepass .forget-form {
  max-width: 310px;
  margin: 0px auto;
}

.forget-section .form-group {
  margin-bottom: 20px;
}


.forget-form h1 {
  font-size: 35px;
  text-align: center;
  font-family: 'Molot';
  color: #fff;
}

.forget-box label {
  color: #fff;
  font-family: 'Roboto-Bold'!important;
}

.forget-box .form-group .form-control {
  background: transparent;
  border-radius: 3px;
  border: 1px solid #ffffff;
  padding: 10px 20px;
  font-size: 14px;
}

.forget-box .form-group .form-control::placeholder {
 color: #999;
}

.forget-button {
  text-align: center;
  padding-top: 30px;
    padding-bottom: 20px;
}



.forget-password{
  text-align: center;
}

.forget-password {
  color: #bbb;
  padding: 10px 0px;
  font-size: 13px;
}

.forget-box {
  font-family: 'Roboto-Light'!important;
}


.forget-box p {
  text-align: center;
  font-size: 13px;
  color: #bbb;
  padding-top: 5px;
}