
.create-tounament .title-page h2:after {
  left: 425px!important;
  width: calc(100% - 425px)!important;
}


.create-tournament-box label {
  color: #fff;
  font-family: 'Roboto-Bold'!important;
  font-style: normal;
}

.create-tournament-box .form-control {
  background: transparent;
  border-radius: 3px;
  border: 1px solid #ffffff;
  padding: 10px 20px;
  font-size: 14px;
  color: #999;
}

.create-tournament-box .form-control::placeholder {
  color: #999;
}

.create-tournament-box {
  padding-top: 30px;
  padding-bottom: 30px;
}

.create-tournament-table .btn-primary-inverse {
  background: transparent;
}

.create-tournament-table .btn-primary-inverse:hover, .create-tournament-table .btn-primary-inverse:focus {
  background: transparent;
}

.create-tournament-box .btn {
  color: #fff;
}

/*************** dropdown css ******************/

.create-tounament .dropdown-toggle {
  background: transparent;
  border: 1px solid #fff;
  width: 100%;
  text-align: left;
  padding: 15px 20px;
  text-transform: inherit;
  border-image-slice: 1!important;
  border-width: 1px!important;
  border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253)!important;
}


.create-tounament .dropdown-toggle:focus {
  background: transparent!important;
  border: 1px solid #fff!important;
  outline: none!important;
  box-shadow: none;
}


.create-tounament .dropdown-toggle::after {
position: absolute;
right: 15px;
top: 20px;
}

.create-tounament .show>.btn-primary.dropdown-toggle{
background-color: transparent;
border-color: #fff;
}


.create-tounament .dropdown-menu {
background: #05020f3d;
width: 100%;
border: 1px solid #949494;
margin-top: 0px;
position: relative!important;
transform: inherit!important;
margin-bottom: 20px;
}

.create-tounament .dropdown-item:hover {
background: #091e3ed1;
color: #fff;
}

.create-tounament .dropdown-item {
font-size: 12px;
color: #fff;
padding: 5px 15px;
}


.create-tounament .dropdown-toggle:hover {
background-color: transparent;
border-color: #3bbdf5;
}

.create-tounament .dropdown-toggle::after {
background: url(../../assets/images/header/select-arrow.png);
content: "";
border: none;
display: block;
width: 18px;
height: 9px;
background-size: cover;
}


.create-tounament .show .dropdown-toggle::after {
transform: rotate(180deg);
}


input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    -webkit-appearance: none;
}

.create-tounament input[type="datetime-local"]:before {
  color: transparent;
  background: none;
  display: block;
  font-family: 'FontAwesome';
  content: '\f073';
  width: 15px;
  height: 20px;
  position: absolute;
  top: 48px;
  right: 36px;
  color: #fff;
}
