.people-list {
  width: 20%;
  float: left;
}
.people-list .search {
  padding: 20px;
  background-color: #504f4d;
}
.people-list input {
  border-radius: 3px;
  border: none;
  padding: 14px;
  color: white;
  background: #242425;
  width: 90%;
  font-size: 14px;
}
.people-list .fa-search {
  position: relative;
  left: -25px;
}
.people-list ul {
    padding: 20px;
    height: 50vh;
    background-color: #dedede;
    margin: 0;
    overflow-y: auto;
    width: 100%;
    margin: 10px 0px;
}
.people-list ul::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #11192f;
}

.people-list ul::-webkit-scrollbar {
  width: 7px;
  background-color: #11192f;
}

.people-list ul::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: #040e29;
}
.people-list ul li {
     padding: 0;
    padding-bottom: 10px;
    padding-top: 10px;

}
.people-list img {
  float: left;
  width: 50px;
  border-radius: 50px;
}
.people-list .about {
  float: left;
  margin-top: 0px;
}
.people-list .about {
  padding-left: 8px;
}
.people-list .status {
  color: #cbcdd2;
}

.chat {
  width: 80%;
  float: left;
  background: #f2f5f8;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #434651;
  position: relative;
}
.chat .chat-header {
  padding: 20px;
  border-bottom: 2px solid white;
}
.chat .chat-header img {
  float: left;
  width: 50px;
  border-radius: 50px;
}
.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
  margin-top: 6px;
}
.chat .chat-header .chat-with {
  font-weight: bold;
  font-size: 16px;
  color: #000;
}
.chat .chat-header .chat-num-messages {
  color: #92959e;
}
.chat .chat-header .fa-star {
  float: right;
  color: #d8dadf;
  font-size: 20px;
  margin-top: 12px;
}
.chat .chat-history {
    padding: 30px 30px 20px;
    border-bottom: 2px solid white;
    overflow-y: scroll;
    height: 50vh;
}
.chat .chat-history .message-data {
  margin-bottom: 15px;
}
.chat .chat-history .message-data-time {
  color: #a8aab1;
  padding-left: 6px;
}
.chat .chat-history .message {
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 30px;
  width: 90%;
  position: relative;
}
.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #86bb71;
  border-width: 10px;
  margin-left: -10px;
}
.chat .chat-history .my-message {
  background: #403351;
}
.chat .chat-history .other-message {
  background: #92969a;
}
.chat .chat-history .other-message:after {
  border-bottom-color: #631111;
  left: 93%;
}

.chat-message {
    padding: 30px;
    display: flex;
    align-items: center;
    width: 100%;
    bottom: 0;
    background: #d6d6d6;
}
 .chat-message input {
  width: 100%;
  border: none;
  padding: 10px 20px;
  font: 14px/22px "Lato", Arial, sans-serif;
  margin-bottom: 0px;
  border-radius: 5px;
  resize: none;
}
 .chat-message .fa-file-o,
 .chat-message .fa-file-image-o {
  font-size: 16px;
  color: gray;
  cursor: pointer;
}
 .chat-message button {
  float: right;
  color: #94c2ed;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
     font-weight: 300;
    background: #f2f5f8;
    background: #333;
    padding: 8px 20px;
    color: #fff;
}
 .chat-message button:hover {
  color: #fff;
}

.online,
.offline,
.me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #86bb71;
}

.offline {
  color: #e38968;
}

.me {
  color: #94c2ed;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
ul.list li::before {
  display: none;
}
.chat li {
  list-style: none;
}
.adminchat {
  padding: 50px 0px;
}
.username {
  color: #230846;
}
.name {
  color: #0c0c0c;
}
.name {
  color: #000;
}

.people-list ul li{
  cursor: pointer;
}

.chat-date-bg {
  background-color: #737373;
  max-width: 100px;
  width: 100%;
  margin: 0px auto;
  border-radius: 20px;
  color: white;
  margin-bottom: 10px;
}

.chat-userr {
    display: flex;
    justify-content: center;
background-color: #dedede;
}
.about.users-name h3 {
    margin: 10px;
    margin-bottom: -6px;
}
 .chat-message input {
    width: 100%;
   
}

.people-list li {
    border-bottom: 1px solid;
}

.message.my-message span {
    font-size: 11px;
    margin-left: 13px;
}
.message.other-message.float-right span {
    font-size: 11px;
    margin-left: 13px;
}
@media screen and (max-width: 767px) {
.people-list{
  width:30% !important;
}

}
