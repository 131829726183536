@media screen and (max-width: 1365px) {
    .navbar-light .navbar-nav .nav-link {
        font-size: 12px;
        letter-spacing: 1px;
    }

    .logo img {
        max-width: 180px;
    }
    .transactionpage .home-table .table > tbody > tr > td {
        padding: 15px 20px;
        white-space: normal !important;
      }
}



@media screen and (max-width: 1280px) {
    .how-it-works-page {
        background-size: 110% !important;
    }

    .home {
        background-size: 115%;
    }

    .login-btn {
        margin-right: 5px;
        font-size: 11px;
        padding: 10px 15px;
    }
    .signup-btn {
        margin-right: 5px;
        font-size: 11px;
        padding: 10px 15px;
    }

    .navbar-light .navbar-nav .nav-link {
        font-size: 11px;
    }
    .navbar-brand {
        margin-right: 10px;
    }

    .logo img {
        max-width: 150px;
    }

    .home-tile-box.tile-1 span {
        font-family: "OhNow";
        font-size: 50px;
        line-height: 15px;
    }
    .home-tile-box.tile-1 h4 {
        font-family: "Molot";
        font-size: 40px;
        margin: 0;
        line-height: 20px;
    }
    .home-tile-box.tile-1 h2 {
        font-family: "Molot";
        font-size: 70px;
        margin: 0;
        line-height: 80px;
    }
    .home-tile-box.tile-2 span {
        font-family: "OhNow";
        font-size: 40px;
        line-height: 20px;
    }
    .home-tile-box.tile-2 h4 {
        font-family: "Molot";
        font-size: 25px;
        margin: 0;
        line-height: 0;
    }
    .home-tile-box.tile-3 span {
        font-family: "OhNow";
        font-size: 40px;
        line-height: 20px;
    }
    .home-tile-box.tile-3 h4 {
        font-family: "Molot";
        font-size: 36px;
        margin: 0;
        line-height: 20px;
    }
    .home-tile-box.tile-4 span {
        font-family: "OhNow";
        font-size: 40px;
        line-height: 32px;
        display: block;
    }
    .tile-4 .home-tile-info {
        position: absolute;
        right: 35px;
        text-align: center;
        bottom: 50px;
    }

    .tile-2 {
        /* background: url(/static/media/tile-2.25f555d1.png); */
        width: 49%;
        background-repeat: no-repeat;
        float: left;
    }
    .tile-3 {
        /* background: url(/static/media/tile-3.bbf196c2.png); */
        width: 49%;
        background-repeat: no-repeat;
        float: right;
    }

    .home-text h2 {
        font-size: 45px;
    }

    .home-text h3 {
        font-size: 30px;
    }

    .game-list-view {
        font-size: 12px;
    }

    .game-list-view {
        font-size: 12px;
    }

    .home .home-banner {
        min-height: auto;
    }

    .home .how-tournament-section{
        min-height: auto;
    }

    .home .day-of-duel-section{
        min-height: auto;
    }

    .home-tile-section {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .how-tournament-section {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .day-of-duel-section {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .try {
        padding: 5px 10px;
        font-size: 12px;
        width: 40px;
    }
    
    .popular-box .game-img .try img {
        width: 20px;
    }

    .many-players img {
        width: 20px;
    }

    .game-action .duel-action img, .game-action .tournament-action img, .game-action .newtournament-action img {
        width: 20px;
    }
.game-action .duel-action img, .game-action .tournament-action img, .game-action .newtournament-action img, .game-action .multiplayer-action img {
    width: 20px;
}
    .game-action .duel-action, .game-action .tournament-action, .game-action .newtournament-action {
        margin-bottom: 2px;
        font-size: 12px;
        width: 40px;
    }

    .game-action .duel-action:hover, .game-action .tournament-action:hover, .game-action .newtournament-action:hover {
        width: 150px;
    }
    

}

@media screen and (max-width: 1199px) {
.header .navbar-light .navbar-toggler {
    margin-top: 6px;
}
.language-dropdown {
    margin-top: 8px;
}
.container {
    max-width: 100%;
}

.navbar-collapse {
    position: fixed;
    width: 0;
    background: #000;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
    transition: all 0.2s;
    opacity: 0;
}

.navbar-collapse.show {
    width: 300px;
    opacity: 1;
}

.navbar-toggler {
    position: absolute;
    right: 0;
    top: 5px;
    display: block!important;
    padding: .25rem 0rem;
}

.language-dropdown {
    position: absolute;
    right: 60px;
    top: 10px;
    margin-right: 0;
}

.navbar-light .navbar-toggler {
    border-color: transparent!important;
}

.navbar-light .navbar-nav .nav-link.active {
    color: #3bbdf5;
    border-bottom: 2px solid transparent;
}
.navbar-light .navbar-nav .nav-link:hover {
    border-bottom: 2px solid transparent;
}

.mobile-nav .navbar-collapse {
    left: unset;
    right: 0;
}

.head-user-info {
    margin-right: 100px;
}


.head-button {
    margin-right: 100px;
}


.navbar-expand-lg .navbar-collapse {
    align-items: flex-start;
}

.navbar-expand-lg .navbar-nav {
    flex-direction: column;
    width: 100%;
}


}





@media screen and (max-width: 1024px) {
    .how-it-works-page {
        background-size: cover !important;
    }

    .home {
        background-size: cover;
    }
    .faq-bg {
        /* background: url("../../assets/images/home/faq-bg.jpg"); */
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 50px;
        background-attachment: fixed !important;
    }
  
}







@media screen and (max-width: 991px) {
    .home-tile-section .col-md-6.tile-slider-one {
        padding-right: 12px;
    }
    .popular-game-section .popular-one,  .popular-game-section .popular-two,  .popular-game-section .popular-three,  .popular-game-section .popular-four{
       padding: 10px;
       } 
       .popular-game-section .popular-one {
        padding-left: 10px !important ;
    }
    .popular-game-section .popular-four {
        padding-left: 10px !important;
    }
       .popular-box{
           height: auto;
       }

    .tile-2 {
        /* background: url(/static/media/tile-2.25f555d1.png); */
        width: 49%;
        background-size: cover;
        background-repeat: no-repeat;
        float: left;
        background-position: center;
    }
    .tile-3 {
        /* background: url(/static/media/tile-3.bbf196c2.png); */
        width: 49%;
        background-size: cover;
        background-repeat: no-repeat;
        float: right;
        background-position: center;
    }


    .home-btns .btn {
        margin-right: 10px;
        padding: 10px 27px;
    }

    .head-button {
        margin-top: 0px;
    }

    .home-text {
        padding-top: 0px;
    }

    .home-text h2 {
        font-size: 40px;
    }

    .home-text h3 {
        font-size: 32px;
    }

    .home-banner {
        padding-bottom: 50px;
    }
    .popular-box {
        margin-bottom: 30px;
    }

    .how-btn .btn {
        padding: 10px 18px;
    }
    .home-tile-box {
        background-size: cover;
    }
    .home-tile-box.tile-3 span {
        font-size: 44px !important;
    }
    .home-tile-box.tile-3 h4 {
        font-size: 35px !important;
    }
    .caution-age p {
        color: #fff;
        font-size: 11px;
        letter-spacing: 0.5px;
        font-family: "Roboto-Light" !important;
    }

    .game-tile .home-tile-info span {
        font-family: "OhNow";
        font-size: 70px !important;
        line-height: 10px !important;
    }
    .game-tile .home-tile-info h4 {
        font-family: "Molot";
        font-size: 30px !important;
        margin: 0;
        line-height: 23px !important;
    }
    .game-tile .home-tile-info h2 {
        font-family: "Molot";
        font-size: 70px !important;
        margin: 0;
        line-height: 91px !important;
    }
    .btn {
        font-weight: 700;
        font-style: italic;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
        transition: all 0.3s ease;
        padding: 12px 15px !important;
        font-size: 12px;
        line-height: 1.5em;
        border-radius: 3px;
    }
    .title-page h2 {
        color: #fff;
        font-family: "Molot";
        font-size: 30px;
        position: relative;
    }

    

    .container {
        max-width: 100%;
    }

    .title-page h2:after{
        display: none;
    }
   

}

@media screen and (min-width: 768px) and (max-width: 1024px){


    .wallet-container .btn::after {
      
        top: 18px;
    }
    .table-game a {

        right: 2px;
        top: 0px;
    }
    .head-user-info .language-dropdown {
        position: absolute;
        right: -29px;
    }

    .table-gamer {
        width: 45%;
    }
    .table-game {
        width: 25%;
    }

    .game-list-view {
        font-size: 11px;
    }
    .table-platform {
        display: none;
    }

    .home-btns .btn {
        margin-right: 5px;
        padding: 6px 10px!important;
        font-size: 11px;
    }

}





@media screen and (max-width: 768px) {



.popular-game-section .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
}

.popular-game-section {
    padding-top: 50px;
    padding-bottom: 50px;
}



.next-link, .prev-link {
    display: none;
}


.home-table {
    padding-top: 20px;
}



.day-of-duel-section .title {
    padding-bottom: 20px;
}

.duel-box {
    padding: 30px 25px;
}



}


























/**************************** Mobile Media Query ****************************/

@media screen and (max-width: 767px){
   /* home page table css */
   .table-game img {
    width: 16px;
}
.table-game a {
    color: #fff;
    position: absolute;
    right: 0px;
    top:auto !important;
}
.game-list-view .table-prize {
    font-size: 15px;
}
.game-list-view .table-game {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.game-list-view {
    font-size: 11px;
}
.game-list-view {
    padding: 5px 5px;
}
.game-head {
    font-size: 11px;
    padding: 5px 5px;
}

    .tile-1 .home-tile-info {
        top: 20px !important;
        position: absolute;
        top: 21px;
        right: 50px;
        text-align: center;
    }
    .home-tile-box .tile-1 h2 {
        font-family: "Molot";
        font-size: 40px !important;
        margin: 0;
        line-height: 50px !important;
    }
    .align-tournament {
        margin-top: 100px;
    }
    .home-tile-box .tile-1 h4 {
        font-family: "Molot";
        font-size: 34px;
        margin: 0;
        line-height: 15px;
    }
    .home-tile-box .tile-1 span {
        font-family: "OhNow";
        font-size: 55px;
        line-height: 20px;
    }
    .home .home-banner {
        min-height: auto;
        padding: 100px 0px;
        height: auto;
        padding-bottom: 0px;
    }
    .tile-1 img {
        /* background: url(./assets/images/home/tile-11.png); */
        width: 100%;
        /* min-height: 173px; */
        height: 100%;
    }
    .home .gamepad-bg{
        height: auto !important;
    }
    .home .how-tournament-section{
        height: auto !important;
    }
    .home .day-of-duel-section{
        height: auto !important;
    }
    .align-tournament {
        margin-top: 0px;
    }
    
    .home-tile-section {
        margin-top: 0px;
      }
      
     
      .home-banner {
        margin-top: 0px;
      }
      .day-of-duel-section {
        margin-top: 0px;
      }
      div#populargame {
        margin-top: 0px;
    }
/**************** Home ***********************/
.home-tile-section .col-md-6.tile-slider-one {
    padding-right: 12px;
}
.popular-game-section .popular-one,  .popular-game-section .popular-two,  .popular-game-section .popular-three,  .popular-game-section .popular-four{
   padding: 10px;
   } 
   .popular-game-section .popular-one {
    padding-left: 10px !important ;
}
.popular-game-section .popular-four {
    padding-left: 10px !important;
}
   .popular-box{
       height: auto;
   }

.payment {
    top: 5px;
    position: relative;
}

.copy-right {
    text-align: center;
}

.table-gamer {
    padding-left: 10px;
}

.home-tile-box.tile-2, .home-tile-box.tile-3 {
    position: relative;
    margin-top: 10px;
}

/**************** Home Active Wallet header ***********************/

.head-user-info .wallet-container {
    margin-left: 0px;
    flex-wrap: nowrap;
    
}
.head-user-info button#dropdown-basic-wallet {
    padding-left: 0px!important;
}

.wallet-container h4 {
    margin-right: 2px;
}

.my-profile-menu {
   
    margin-left: 0px;
    
}
.wallet-container .btn::after {
    
    top: 19px;
}
.title-page h2:after {
  
    z-index: 0;
}
.head-user-info .dropdown-menu {
    min-width: 9rem;
    left: -36px;
}

.language-dropdown .dropdown-menu {
    min-width: 9rem;
    right: 0;
    left: -41px;
}
/* .head-user-info {position: relative;} */

/* .head-user-info .wallet-container {
    margin-left: 90px!important;
} */
.my-profile-menu .dropdown {
  
    right: -16px;
   
}
.my-profile-menu {
    position: absolute!important;
    top: 12px!important;
    right: 116px;
}
.language-dropdown {
    position: absolute!important;
    top: 10px!important;
    right: 0;
}
.language-dropdown {
    margin-right: 60px;
}
.head-user-info {
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    justify-content: flex-end;
}

/**************** match-making-popup***********************/


.match-making-popup .modal-content {
   
    background-size: 460px!important;
    
}
.match-box h4 {
    font-size: 20px;
 
}
.wrapperr h1 {
    font-size: 50px;
 
}


.popular-box .game-img img {
    width: 100%;
    height: 220px;
    object-fit: cover;
}




}























@media screen and (max-width: 480px){

.popular-game-section .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
}
.head-button {
    display: block;
    margin: 0;
    position: absolute;
    right: -2px;
    top: 50px;
}

}





@media screen and (max-width: 360px){

.language-dropdown {
    margin-right: 40px;
}

.my-profile-menu {
    right: 100px;
}

.wallet-container + .add-money {
    display: none;
}

}





@media screen and (min-width: 768px) {

    .mobile{
    
        display:none;
    }
    
    
    }
    
    