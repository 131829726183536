.profile .card-body {
    display: block;
    padding: 0;
    border: none;
    font-size: 13px;
}


.profile .card {
    border: none;
    border-radius: 0;
    background-color: transparent;
    margin-bottom: 15px;
    color: #fff;
    overflow: inherit;

}


.profile {
    background: url("../../assets/images/home/profile-bg.jpg");
    padding: 50px 0px;
    background-size: cover;
    background-attachment: fixed;
}


.profile .title-page h2:after {
    left: 180px;
    width: calc(100% - 180px);
}

.profile-content {
    padding: 100px 0px;
}


.ui-w-100 {
    width: 100px;
    height: auto;
    margin: 0px auto;
}

.profile .card-footer {
    border: none;
}

.profile-pic {
    text-align: center;
}

.profile .card-body p {
    font-size: 13px;
}


.profile-content h4 {
    color: #fff;
    font-family: "Molot";
    font-size: 30px;
}


.profile .tournament-block table thead tr th {
    background: transparent;
    border: 1px solid #f7f7f7;
    border-top: 1px solid #f7f7f7 !important;
    color: #fff;
}

.profile .tournament-block table tbody tr td {
    border: 1px solid #f7f7f7;
    color: #fff;
    font-weight: 100;
    background: transparent;
    padding: 15px;
    vertical-align: middle;
}

.profile .table-bordered {
    margin-bottom: 0;
}

.profile .card-body.row.no-gutters {
    display: flex;
}

.edit-btn {
    font-size: 13px;
    color: #fff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    border-radius: 3px;
    font-weight: 500;
    transition: all 0.5s;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    display: inline-block;
    margin-top: 20px;
}

.edit-btn:hover,
.edit-btn:focus {
    background-color: #205388;
    border-color: #205388;
    color: #fff;
}


.tournament-profile {
    background-color: #1c1c1c;
    border-color: #1c1c1c;
    margin-right: 10px;
    font-size: 13px;
    color: #fff !important;
    padding: 10px 21px;
    text-decoration: none;
    border-radius: 50px;
    font-weight: 500;
    transition: all 0.5s;
    position: absolute;
    right: 149px;
    top: 8px;
}

.tournament-profile:hover,
.tournament-profile:focus {
    background-color: #205388;
    border-color: #205388;
    color: #fff;
}

.flag-img {
    box-shadow: 0px 1px 2px #0000005c;
    width: 30px;
    position: absolute;
    bottom: 10px;
    right: 6px;
}


.profile .home-table .table>tbody>tr>td {
    padding: 15px 20px;
}

.profile .home-table .table>thead>tr>th {
    padding: 15px 20px;
}

.profile .home-table .table>thead>tr>th.date {
    padding-left: 20px !important;
}

.mb-100 {
    margin-bottom: 100px;
}

.profile-content .border-btn {
    margin-top: 20px;
    margin-right: 10px;
    padding: 9px 15px;
}

.profile-content .text-muted {
    color: #ffffff80 !important;
}

.profile-content a,
.profile-content a:hover {
    color: #ffffff;
}


.profile-content .border-btn img {
    width: 15px;
    margin-right: 5px;
}

.user-profile img {
    width: 115px;
    height: 115px;
    object-fit: cover;
}

/* .profile .home-table .table-bordered > thead > tr > th:first-child {
    width:45%
} */

/* .profile .my-friends .home-table .table > tbody > tr > td:first-child {
    padding: 0;
} */

.profile .my-friends .home-table .table>tbody>tr>td img {
    width: 88px;
    height: 88px;
    object-fit: cover;
}


.profile .my-friends .home-table .table>tbody>tr>td .btn-primary {
    padding: 12px 20px;
    margin-bottom: 5px;
}

.profile .my-friends .home-table .table>tbody>tr>td a:hover {
    border-bottom: 1px solid #3bbdf5;
}

.profile .my-friends .home-table .table>tbody>tr>td a {
    padding-bottom: 5px;
}

.profile .my-friends .home-table .table-bordered>thead>tr>th:first-child {
    width: auto;
}

/* .profile .my-friends .home-table .table-bordered > thead > tr > th:nth-child(2) {
    width: 45%;
} */

.user-profile .profile-input {
    width: 115px;
    height: 115px;
    position: absolute;
    top: 0;
    overflow: hidden;
    opacity: 0;
    z-index: 99;
    cursor: pointer;
}

.user-pic-box {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
}


.user-profile {
    max-width: 115px;
}

.user-profile .edit-btn {
    width: 100%;
}


.user-pic-box:hover form::before,
.user-pic-box:hover form::after {
    opacity: 1;
}

.user-pic-box form::before {
    content: "";
    background: #019bcdd9;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    opacity: 0;
    transition: all 0.5s;
}

.user-pic-box form::after {
    content: "";
    background: url("../../assets/images/header/upload-icon.png");
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.5s;
}

.profile-content .previous,
.next {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding: 5px 20px;
    margin: 5px;
}

.profile-content .pagination-section {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
}

.profile .home-table .table>tbody>tr,
.home-table .table>thead>tr {
    /* border: 1px solid#3d4253; */
    display: grid;
    grid-template-columns: calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7) !important;
    position: relative;
}

.date {
    padding-left: 38px !important;
}

.profile-content .border-btn:focus {
    background: transparent !important;
    border: 1px solid #fff !important;
    outline: none !important;
    box-shadow: none;
}