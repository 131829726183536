.btn-primary-noninverse {
    color: #fff;
    background-color: #d71b1b9c;
    border-color: transparent;
}



.react-quill {
    margin: 1rem 4rem;
}

.react-quill .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
    color: #000000
}

/* Snow Theme */
.react-quill .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.react-quill .ql-bubble .ql-editor {
    border: 1px solid #ccc;
    border-radius: 0.5em;
}

.react-quill .ql-editor {
    min-height: 18em;
}

/****** POPUP CSS ******/

.kycmodal .modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
  }
  .kycmodal .modal-header{
    font-family: 'Roboto-Bold';
  }
  .kycmodal .modal-dialog .form-label {
    font-size: 16px;
    font-family: 'Roboto-Bold';
  }
  .kycmodal .modal-dialog img {
    width: 160px;
    height: 140px;
    object-fit: contain;
  }
  .kycmodal .submitt {
    display: flex;
    justify-content: flex-end;
  }