.features-section{
    background: url("../../assets/images/home/feature-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 150px!important;
}

.features-section .title-page h2:after {
    left: 200px;
    width: calc(100% - 200px);
}

.features-section .title-page p {
    color: #d4d4d4;
    font-size: 12px;
    letter-spacing: 1px;
    font-family: 'Roboto-Light'!important;
    max-width: 450px;
    padding-top: 20px;

}

.features-section .title-page {
    padding-bottom: 0px;
}


.feature-box {
    padding-top: 50px;
}

.feature-box h3 {
    color: #fff;
    font-family: "Molot";
    font-size: 22px;
    position: relative;
    padding-bottom: 10px;
}

.feature-box p {
    color: #e0e0e0;
    font-size: 13px;
    letter-spacing: 1px;
    font-family: 'Roboto-Light'!important;
}